import { useParams } from "react-router-dom";
import Footer from "../../../components/Footers";
import "../../../App.css";
import image7 from "../../../assets/img/image 7.png";
import image8 from "../../../assets/img/image 8.png";
import image9 from "../../../assets/img/image 9.png";
import image10 from "../../../assets/img/image 10.png";
import React, {
  useState,
  useEffect,
} from "react";
import urls from "../../../services/axios/urls";
import axios from "axios";
import { toast } from "react-toastify";

export const SingleTrip = () => {
  const { id: pageId } = useParams();

  const [tripData, setTripData] = useState([]);
  console.log(tripData);

  const URI = urls.trip(pageId);

  const fetchTripData = async () => {
    try {
      const response = await axios.get(URI);
      setTripData(response.data);
    } catch (error) {
      toast.error(error);
    }
  };

  console.log(pageId);

  useEffect(() => {
    fetchTripData();
  }, [pageId]);

  return (
    <>
      <div className="bg-hero relative">
        <ReserveSection />
      </div>
      <BackgroundSection />
      <BrandedSection />
      <Footer />
    </>
  );
};

const ReserveSection = () => {
  return (
    <div className="relative">
      <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:py-32 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl sm:text-5xl lg:text-5xl font-light tracking-tight text-secondary pt-5">
            Kings Air and Tour
          </h1>
          <h1 className="text-3xl sm:text-5xl lg:text-5xl font-light tracking-tight text-secondary">
            Travel
          </h1>
          <p className="mt-6 text-base sm:text-lg lg:text-xl leading-6 text-white mb-8">
            Ready to Explore? These Trips Await!
          </p>
          <input
            className="peer w-full h-full bg-transparent text-white text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
            placeholder="Search"
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

const BackgroundSection = () => {
  return (
    <div style={{ paddingTop: "20px" }}>
      <h1
        className="text-3xl font-bold mb-6"
        style={{
          paddingTop: "100px",
          marginLeft: "72px ",
        }}
      >
        New York, USA
      </h1>
      <div className="container text-center mt-4 mb-4 bg">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6"></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
            }}
          >
            <img
              src={image7}
              alt="Image 1"
              className="img-fluid"
              style={{
                maxWidth: "85%",
                marginBottom: "20px",
              }}
            />
            <img
              src={image8}
              alt="Image 2"
              className="img-fluid"
              style={{
                maxWidth: "85%",
                marginBottom: "20px",
              }}
            />
            <img
              src={image9}
              alt="Image 3"
              className="img-fluid"
              style={{
                maxWidth: "85%",
                marginBottom: "20px",
              }}
            />
            <img
              src={image10}
              alt="Image 4"
              className="img-fluid"
              style={{
                maxWidth: "85%",
                marginBottom: "20px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const BrandedSection = () => {
  const [showSignUpModal, setShowSignUpModal] =
    useState(false);

  const toggleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal);
  };

  return (
    <div className="container mx-auto branded-section">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 p-8 branded">
          <h1 className="font-bold text-3xl md:text-4xl">
            7 days trip to New York, USA
          </h1>
          <h6
            className="font-bold"
            style={{ width: "60px" }}
          >
            Description
          </h6>
          <p className="my-4">
            Explore the vibrant of New York for a
            7 <br />
            days trip.
          </p>
          <p className="my-4">
            You will explore different places like
            La statut de la liberte, Time Square
            and more.
          </p>
          <div>
            <button
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-full shadow-md mr-4 mb-10"
              onClick={toggleSignUpModal}
            >
              Book this trip
            </button>

            <button
              className="bg-gray font-bold py-2 px-4 rounded-full opacity-70 cursor-not-allowed mb-10"
              disabled
            >
              Ask question
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-8 branded">
          <h3 className="font-bold">
            Included in this trip
          </h3>
          <ul className="list-disc pl-4 my-4">
            <li>
              Transportation to destinations
            </li>
            <li>Breakfast, Lunch, Dinner</li>
            <li>Entry fees the places</li>
          </ul>
        </div>
      </div>

      {showSignUpModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg relative">
            <button
              className="absolute top-0 right-0 m-2 text-gray-600 cursor-pointer"
              onClick={toggleSignUpModal}
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">
              Finalize the booking
            </h2>
            <form className="relative w-full max-w-md mx-auto">
              <div className="flex justify-center">
                <div className="mb-4">
                  <input
                    type="text"
                    id="firstName"
                    className="border rounded w-full p-2 m-2"
                    placeholder="First Name"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    id="lastName"
                    className="border rounded w-full p-2 m-2"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  id="email"
                  className="border rounded w-full p-2"
                  placeholder="Email Address"
                />
              </div>
              <div className="mb-4">
                <input
                  type="tel"
                  id="phonenumber"
                  className="border rounded w-full p-2"
                  placeholder="+23353300000"
                />
              </div>

              <input
                type="submit"
                className="bg-primary text-white font-bold py-2 px-4 rounded-full shadow-md mr-4 w-full"
                value="Book trip"
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleTrip;
