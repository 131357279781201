import { useState } from "react";
import FormInput from "../../components/FormField";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import urls from "../../services/axios/urls";

export default function StartOnboarding() {
  const userId = JSON.parse(
    localStorage.getItem("authToken")
  ).id;
  if (!userId) {
    alert("Please login to continue");
    toast.error("Please login to continue");
    window.location.href = "/login";
  }
  // const authToken = JSON.parse(
  //   localStorage.getItem("authToken")
  // ).token;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] =
    useState("");
  const [brandName, setBrandName] = useState("");
  const [companyRegNumber, setCompanyRegNumber] =
    useState("");
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };
  const handleBrandNameChange = (e) => {
    setBrandName(e.target.value);
  };

  const handleCompanyRegNumberChange = (e) => {
    setCompanyRegNumber(e.target.value);
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName) {
      toast.error(
        "First name and last name are required"
      );
      return;
    }

    const formData = {
      firstName: firstName,
      lastName: lastName,
      companyName: companyName || null,
      brandName: brandName || null,
      companyRegNumber: companyRegNumber || null,
      country: country || null,
      city: city || null,
    };

    const URI = urls.createProfile(userId);
    const authToken = JSON.parse(
      localStorage.getItem("authToken")
    ).token;

    try {
      const response = await fetch(URI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (
        response.status === 200 ||
        response.status === 201
      ) {
        toast.success("Data Saved Successfully");
        window.location.href =
          "/admin/complete-onboarding";
      } else {
        throw new Error(
          data.message ||
            "An error occurred while processing your request."
        );
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="ml-10">
        <h1 className="text-2xl sm:text-4xl lg:text-5xl font-bold text-primary mb-4">
          Set Up Profile
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 md:p-4"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 md:p-10">
        <div className="">
          <FormInput
            label="First Name"
            id="firstName"
            name="firstName"
            type="text"
            placeholder="John *"
            value={firstName}
            onChange={handleFirstNameChange}
            required
          />
          <FormInput
            label="Company Name"
            id="companyName"
            name="companyName"
            type="text"
            placeholder="Djara Travels"
            value={companyName}
            onChange={handleCompanyNameChange}
          />
          <FormInput
            label="Company Reg Number"
            id="companyRegNumber"
            name="companyRegNumber"
            type="text"
            placeholder="123456 *"
            value={companyRegNumber}
            onChange={
              handleCompanyRegNumberChange
            }
          />

          <FormInput
            label="Country"
            id="country"
            name="country"
            type="text"
            placeholder="Country *"
            value={country}
            onChange={handleCountryChange}
          />
        </div>
        <div>
          <FormInput
            label="Last Name"
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Doe *"
            value={lastName}
            onChange={handleLastNameChange}
            required
          />
          <FormInput
            label="Brand Name"
            id="brandName"
            name="brandName"
            type="text"
            placeholder="Brand (optional)"
            value={brandName}
            onChange={handleBrandNameChange}
          />
          <FormInput
            label="Website"
            id="website"
            name="website"
            type="url"
            placeholder="https://example.com (optional)"
            value={website}
            onChange={handleWebsiteChange}
          />
          <FormInput
            label="City"
            id="city"
            name="city"
            type="text"
            placeholder="City (optional)"
            value={city}
            onChange={handleCityChange}
          />
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
              onClick={handleOnSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
