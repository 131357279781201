import React, { useState } from "react";
import FormInput from "../../components/FormField";
import axios from "axios";
import urls from "../../services/axios/urls";
import { toast } from "react-toastify";

export default function CompleteOnboarding() {
  const userId = JSON.parse(
    localStorage.getItem("authToken")
  ).id;
  if (!userId) {
    alert("Please login to continue");
    toast.error("Please login to continue");
    window.location.href = "/login";
  }
  const [profilePicture, setProfilePicture] =
    useState(null);
  const [profileName, setProfileName] =
    useState("");
  const [description, setdescription] =
    useState("");
  const [
    profileBackground,
    setProfileBackground,
  ] = useState(null);

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleProfileNameChange = (e) => {
    setProfileName(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };

  const handleProfileBackgroundChange = (e) => {
    setProfileBackground(e.target.files[0]);
  };

  const handlePreviousButton = () => {
    window.location.href = "/admin/onboarding";
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append(
      "profileBackground",
      profileBackground
    );
    formData.append("profileName", profileName);
    formData.append("description", description);
    formData.append(
      "profilePicture",
      profilePicture
    );
    const URI = urls.updateProfile(userId);
    axios
      .patch(URI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (
          response.status == 200 ||
          response.status == 201
        ) {
          toast.success(
            "Profile updated successfully"
          );
          window.location.href =
            "/admin/create-trip";
        }
        setProfilePicture(null);
        setProfileName("");
        setdescription("");
        setProfileBackground(null);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="ml-10">
        <h1 className="text-2xl sm:text-4xl lg:text-5xl font-bold text-primary mb-10">
          Complete Profile
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 md:p-10">
        <div>
          <div className="mb-4">
            <label
              htmlFor="profileBackground"
              className="text-sm bg-primary"
            >
              Profile Background
            </label>
            <input
              type="file"
              id="profileBackground"
              name="profileBackground"
              onChange={
                handleProfileBackgroundChange
              }
              className="mt-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="profilePicture"
              className="text-sm"
            >
              Profile Picture
            </label>
            <input
              type="file"
              id="profilePicture"
              name="profilePicture"
              onChange={
                handleProfilePictureChange
              }
              className="mt-2"
            />
          </div>
          <FormInput
            label="Profile Name"
            id="profileName"
            name="profileName"
            type="text"
            placeholder="Profile Name *"
            value={profileName}
            onChange={handleProfileNameChange}
            required
          />
          <FormInput
            label="Profile Description"
            id="description"
            name="description"
            type="text"
            placeholder="Profile Description (optional)"
            value={description}
            onChange={handledescriptionChange}
          />
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
          onClick={handlePreviousButton}
        >
          Previous
        </button>
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
          onClick={handleOnSubmit}
        >
          Create First Trip
        </button>
      </div>
    </>
  );
}
