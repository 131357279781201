import { useState } from "react";
import login from "../../assets/img/login.png";
import logo from "../../assets/img/logo.png";
import FormInput from "../../components/FormField";
import urls from "../../services/axios/urls";
import axios from "axios";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] =
    useState("");
  const [isChecked, setIsChecked] =
    useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (
      !email ||
      !password ||
      !confirmPassword ||
      !isChecked
    ) {
      toast.error("Please fill in all fields");
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const formData = {
      email,
      password,
      confirmPassword,
    };
    try {
      const response = await axios.post(
        `${urls.register}`,
        formData
      );
      if (response.data.access_token) {
        localStorage.setItem(
          "authToken",
          JSON.stringify({
            token: response.data.access_token,
            id: response.data.id,
            isAuthenticated: true,
          })
        );
        toast.success(
          "Account successfully created"
        );
        window.location.href =
          "/admin/onboarding";
      }
    } catch (err) {
      toast.error(err.message);
    }
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setIsChecked(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="flex justify-end">
        <img src={logo} alt="logo" className="" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 md:p-10">
        <div>
          <img
            src={login}
            alt="photo"
            className=""
          />
        </div>
        <div>
          <div className="text-2xl sm:text-4xl lg:text-5xl font-bold text-primary mb-10">
            <h1>Sign Up</h1>
          </div>
          <p className="mb-8">
            Your jouney start here
          </p>
          <form className="mr-50">
            <FormInput
              label="Email"
              id="email"
              name="email"
              type="email"
              placeholder="johndoe@gmail.com"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <FormInput
              label="Password"
              id="password"
              name="password"
              type="password"
              placeholder="PASSword1234?}!?"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <FormInput
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="PASSword1234?}!?"
              value={confirmPassword}
              onChange={
                handleConfirmPasswordChange
              }
              required
            />
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="mr-2"
                required
              />
              <label
                htmlFor="terms"
                className="text-sm"
              >
                I agree to the{" "}
                <a
                  href="/terms"
                  className="text-primary"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>
            <button
              type="submit"
              className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
              onClick={handleOnSubmit}
            >
              Create Account
            </button>
          </form>
          <p className="mt-4">
            Already have an account?{" "}
            <a
              href="/login"
              className="text-primary"
            >
              Login
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
