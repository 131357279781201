import { useState } from "react";
import login from "../../assets/img/login.png";
import logo from "../../assets/img/logo.png";
import FormInput from "../../components/FormField";
import axios from "axios";
import urls from "../../services/axios/urls";
import {
  toast,
  ToastContainer,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import useAuthStore from "../../state/authStore";

export default function LoginPage() {
  // const reset = useAuthStore(
  //   (state) => state.reset
  // );

  // useEffect(() => {
  //   reset();
  // }, [reset]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("All fields are required");
      return;
    }

    const formData = {
      email,
      password,
    };
    try {
      const response = await axios.post(
        `${urls.login}`,
        formData
      );

      if (response.data.access_token) {
        localStorage.setItem(
          "authToken",
          JSON.stringify({
            token: response.data.access_token,
            id: response.data.id,
            isAuthenticated: true,
          })
        );
        toast.success("Login successful");
        window.location.href = "/admin";
      }
    } catch (err) {
      toast.error(
        err.response
          ? err.response.data.message
          : err.message
      );
    }

    setEmail("");
    setPassword("");
  };

  return (
    <>
      <ToastContainer />
      <div className="flex justify-end">
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className=""
          />
        </a>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 md:p-10">
        <div>
          <div className="text-2xl sm:text-4xl lg:text-5xl font-bold text-primary mb-10">
            <h1>Login</h1>
          </div>
          <p className="mb-8">
            Welcome back! Please login to your
            account.
          </p>
          <form
            onSubmit={handleOnSubmit}
            className="mr-50"
          >
            <FormInput
              label="Email"
              id="email"
              name="email"
              type="email"
              placeholder="johndoe@gmail.com"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <FormInput
              label="Password"
              id="password"
              name="password"
              type="password"
              placeholder="***************"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button
              type="submit"
              className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
            >
              Login
            </button>
          </form>
          <p className="mt-4">
            Don&apos;t have an account?{" "}
            <a
              href="/register"
              className="text-primary"
            >
              Sign up
            </a>
          </p>
        </div>
        <div>
          <img
            src={login}
            alt="photo"
            className=""
          />
        </div>
      </div>
    </>
  );
}
