import React from "react";
import { Outlet } from "react-router-dom";
import "./styles.css";
import SideBar from "../../components/Sidebar";
// import logo from "../../assets/img/logo.png";
import AdminNavbar from "../../components/NavBars/AdminNavbar";

const Admin = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AdminNavbar
        menuItems={[
          {
            label: "Profile",
            link: "/admin/profile",
          },
          // {
          //   label: "Reset Password",
          //   link: "/reset-password",
          // },
          // {
          //   label: "Settings",
          //   link: "/admin/settings",
          // },
          // {
          //   label: "Get Help",
          //   link: "/get-help",
          // },
        ]}
      />
      <div className="flex flex-1">
        <div className="sidebar">
          <SideBar
            menuItems={[
              {
                to: "/admin/dashboard",
                label: "Dashboard",
                icon: "📊",
              },
              {
                to: "/admin/bookings",
                label: "Bookings",
                icon: "📅",
              },
              {
                to: "/admin/trips",
                label: "Trips",
                icon: "🚗",
              },
              {
                to: "/admin/inquiries",
                label: "Inquiries",
                icon: "📝",
              },
              {
                to: "/admin/profile",
                label: "Profile",
                icon: "⚙️",
              },
            ]}
          />
        </div>
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

// const DashboardNav = () => {
//   const handleLogoutButtonClick = () => {
//     localStorage.removeItem("authToken");
//     window.location.href = "/login";
//   };
//   return (
//     <div className="flex justify-between items-center p-4 bg-white shadow-md">
//       <h1 className="text-2xl font-bold">
//         <div className="flex justify-center items-center">
//           <img src={logo} alt="logo" />
//         </div>
//       </h1>
//       <button
//         onClick={handleLogoutButtonClick}
//         className="bg-red-500 text-primary px-4 py-2 rounded-md"
//       >
//         Logout
//       </button>
//     </div>
//   );
// };

export default Admin;
