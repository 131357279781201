import React from "react";

const Dashboard = () => {
  const totalTrips = 0; // Replace with actual value
  const totalBookings = 0; // Replace with actual value
  const totalInquiries = 0; // Replace with actual value

  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="bg-gray-200 p-4 rounded-md">
        <h2 className="text-lg font-semibold mb-2">
          Total Trips
        </h2>
        <p className="text-3xl font-bold">
          {totalTrips}
        </p>
      </div>
      <div className="bg-gray-200 p-4 rounded-md">
        <h2 className="text-lg font-semibold mb-2">
          Total Bookings
        </h2>
        <p className="text-3xl font-bold">
          {totalBookings}
        </p>
      </div>
      <div className="bg-gray-200 p-4 rounded-md">
        <h2 className="text-lg font-semibold mb-2">
          Inquiries
        </h2>
        <p className="text-3xl font-bold">
          {totalInquiries}
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
