import React, {
  useState,
  useEffect,
} from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import urls from "../../../services/axios/urls";
import { toast } from "react-toastify";

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urls.bookings}`
        );
        const { pageEdges, totalCount } =
          response.data;
        setBookings(
          pageEdges.map((edge) => edge.node)
        );
        setTotalCount(totalCount);
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = (id) => {
    console.log("Update booking with ID:", id);
  };

  const handleDelete = (id) => {
    console.log("Delete booking with ID:", id);
  };

  return (
    <div className="overflow-x-auto">
      <div className="mb-4">
        <p>Total Count: {totalCount}</p>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="text-left py-2 px-4">
              Name
            </th>
            <th className="text-left py-2 px-4">
              Trip
            </th>
            <th className="text-left py-2 px-4">
              Trip Date
            </th>
            <th className="text-left py-2 px-4">
              Email
            </th>
            <th className="text-left py-2 px-4">
              Phone Number
            </th>
            <th className="text-left py-2 px-4">
              Price
            </th>
            <th className="text-left py-2 px-4">
              Payment Status
            </th>
            <th className="text-left py-2 px-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking.id}>
              <td className="border-t py-2 px-4">
                {booking.firstName}{" "}
                {booking.lastName}
              </td>
              <td className="border-t py-2 px-4">
                {booking.trip.title}
              </td>
              <td className="border-t py-2 px-4">
                {new Date(
                  booking.createdAt
                ).toLocaleDateString()}
              </td>
              <td className="border-t py-2 px-4">
                {booking.email}
              </td>
              <td className="border-t py-2 px-4">
                {booking.phoneNo || "NA"}
              </td>
              <td className="border-t py-2 px-4">
                {booking.trip.price}{" "}
                {booking.trip.currency}
              </td>
              <td className="border-t py-2 px-4">
                {booking.status
                  ? "Paid"
                  : "Pending"}
              </td>
              <td className="border-t py-2 px-4">
                <div className="flex">
                  <button
                    className="mr-2 text-blue-500 hover:text-blue-700"
                    onClick={() =>
                      handleUpdate(booking.id)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                    />
                    {/* Update */}
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() =>
                      handleDelete(booking.id)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                    />
                    {/* Delete */}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Bookings;
