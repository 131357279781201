import React from "react";
import Button from "../../components/Button";
import Navbar from "../../components/NavBars/Navbar";
import image2 from "../../assets/img/image 2.png";
// import imager from "../../assets/img/imager.png";
// import imageq from "../../assets/img/imageq.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHouse } from "@fortawesome/free-solid-svg-icons";
// import { faBookBookmark } from "@fortawesome/free-solid-svg-icons";
// import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

export default function LandingPage() {
  return (
    <>
      <div className="">
        <Nav />
        <HeroSection />
        <ColumnSection />
        <WhyGomonjiSection />
        <FlexBoxComponent />
        <Howitworks />
        <TryitNow />
        <Footer />
      </div>
    </>
  );
}
const Nav = () => {
  return (
    <nav className="bg-white h-16">
      <Navbar />
    </nav>
  );
};
const HeroSection = () => {
  const handleJoinWaitLisButton = () => {
    window.location.href = "/login";
  };

  const handleBecomeBetaButton = () => {
    window.location.href = "/register";
  };
  return (
    <div
      className="relative bg-landing -inset-0 flex justify-center"
      style={{ height: "700px" }}
    >
      <div className="absolute inset-x-0 -top-40 sm:-top-80 -z-10 transform-gpu overflow-hidden blur-3xl"></div>
      <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:py-32 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-primary">
            Elevate Your Travel Business Online
          </h1>
          <p className="mt-6 text-base sm:text-lg lg:text-xl leading-6">
            Empower Your Agency, Connect with
            Travelers and Thrive Globally.
          </p>
          <br />
          <Button
            variant="secondary"
            label="Login"
            onClick={handleJoinWaitLisButton}
          />
          <Button
            label="Get Started"
            onClick={handleBecomeBetaButton}
            className="ml-4"
            variant="primary"
          />
          <div className="pu-10">
            <img
              src={image2}
              alt="image 2"
              className="absolute bottom-0"
              style={{
                maxWidth: "45%",
                top: "55%",
                paddingTop: "2px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnSection = () => {
  return (
    <div
      className="bg-graylight h-fit"
      style={{
        marginTop: "0px",
        marginBottom: "0px",
        paddingBottom: "70px",
        paddingTop: "220px",
        paddingLeft: "150px",
        paddingRight: "150px",
      }}
    >
      <div className="flex justify-center">
        {/* Column 1 - 3 */}
        <div
          className="w-full md:w-1/2 p-8 branded"
          style={{
            marginRight: "0px",
            marginLeft: "50px",
          }}
        >
          <div className="text-left flex justify-center items-center">
            <h2 className="text-2xl sm:text-4xl lg:text-4xl font-bold tracking-tight text-primary">
              Empower your activity, connect with
              your customers and thrive globally
            </h2>
          </div>
        </div>

        {/* Column 2 - 9 */}
        <div
          className="w-full md:w-1/2 p-8 branded text-center md:text-left "
          style={{
            marginLeft: "0px",
            marginRight: "100px",
            paddingRight: "0px",
          }}
        >
          <div className="text-sm-center md:text-left flex justify-center items-center">
            <p className="mt-6 text-base sm:text-lg lg:text-xl leading-6">
              Gomonji is the ultimate solution for
              travel agencies, agents, and tour
              operators seeking to establish a
              robust online presence. We
              understand the evolving landscape of
              the travel industry and provide the
              tools and support necessary to help
              you succeed in the digital realm.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const WhyGomonjiSection = () => {
  return (
    <div
      className="container mx-auto px-4 py-8"
      style={{
        marginTop: "70px",
        marginBottom: "-50px",
      }}
    >
      <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-primary mb-8 text-center">
        Why Gomonji?
      </h1>
    </div>
  );
};

const FlexBoxComponent = () => {
  return (
    <div
      className="container mx-auto px-4 py-8"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <div className="flex justify-evenly">
        {/* <!-- Section 1 --> */}
        <div className="w-1/3 p-4">
          <div className="flex items-center justify-items-center bg-blue-500 text-primary rounded-full h-12 w-12 mb-4">
            <svg
              // xmlns="http://www.w3.org/2000/svg"
              fill="none"
              // viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 fill-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>
          </div>
          <h2 className="text-lg font-semibold mb-2 text-primary sm:text-2xl lg:text-3xl text-center">
            Increase your online presence
          </h2>
          <ul
            className="list-disc list-inside text-m sm:text-1xl lg:text-0xl"
            style={{
              paddingLeft: "50px",
              paddingRight: "30px",
            }}
          >
            <li>
              Set up a free website in 5 minutes
            </li>
            <li>
              Get seen on Google and other search
              engines
            </li>
            <li>
              Get inquiries all in one place
            </li>
          </ul>
        </div>
        {/* <!-- Section 2 --> */}
        <div className="w-1/3 p-4">
          <div className="flex items-center justify-center bg-blue-500 text-primary rounded-full h-12 w-12 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
              />
            </svg>
          </div>
          <h2 className="text-lg font-semibold mb-2 text-primary sm:text-2xl lg:text-3xl text-center">
            Handle all your travel packages in one
            place
          </h2>
          <ul
            className="list-disc list-inside text-m sm:text-1xl lg:text-0xl"
            style={{
              paddingLeft: "40px",
              paddingRight: "20px",
            }}
          >
            <li>
              Create travel packages and get
              booking in one platform
            </li>
            <li>
              Keep track of all your packages
            </li>
            <li>
              Distribute your package on Gomonji
              marketplace
            </li>
          </ul>
        </div>
        {/* <!-- Section 3 --> */}
        <div className="w-1/3 p-4 justify-center">
          <div className="flex items-center justify-center bg-blue-500 text-primary rounded-full h-12 w-12 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
              />
            </svg>
          </div>
          <h2 className="text-lg font-semibold mb-2 text-primary sm:text-2xl lg:text-3xl text-center">
            Get paid faster with less hassle
          </h2>
          <ul
            className="list-disc list-inside text-m sm:text-1xl lg:text-0xl"
            style={{
              paddingLeft: "25px",
              paddingRight: "0px",
            }}
          >
            <li>
              Collect payment through different
              methods
            </li>
            <li>
              Get paid in foreign currencies
            </li>
            <li>
              Track your customers’ payment
              schedules
            </li>
            <li>Send automatic reminders</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const Howitworks = () => {
  return (
    <div className="bg-graylight">
      <div
        className="container mx-auto px-4 py-8"
        style={{
          marginTop: "70px",
          marginBottom: "0px",
          paddingTop: "100px",
        }}
      >
        <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-primary mb-8 text-center">
          How it Works?
        </h1>
      </div>
    </div>
  );
};

const TryitNow = () => {
  const handleJoinWaitLisButton = () => {
    window.location.href = "/login";
  };

  const handleBecomeBetaButton = () => {
    window.location.href = "/register";
  };
  return (
    <div className="bg-primary">
      <div
        className="container mx-auto px-4 py-8 in-line justify-items-center"
        style={{
          marginTop: "0px",
          paddingBottom: "100px",
          paddingTop: "100px",
        }}
      >
        <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-secondary mb-8 text-center">
          Try Gomonji now!
        </h1>
        <p
          className="mt-6 text-base sm:text-lg lg:text-xl leading-6 text-center text-white"
          style={{
            paddingLeft: "250px",
            paddingRight: "250px",
          }}
        >
          Ready to take your travel business to
          the next level? Sign up for free of
          Gomonji today and experience the
          difference for yourself.
        </p>
        <div />
        <div
          className="flex justify-center"
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            paddingTop: "50px",
          }}
        >
          <Button
            variant="secondary"
            label="Login"
            onClick={handleJoinWaitLisButton}
          />
          <Button
            variant="white"
            label="Get Started"
            onClick={handleBecomeBetaButton}
            className="ml-4"
          />
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="bg-white">
      <div
        className="container mx-auto px-4 py-8"
        style={{
          marginTop: "30px",
          marginBottom: "-50px",
          paddingTop: "10px",
        }}
      >
        <p className="text-0xl sm:text-0xl lg:text-0xl font-bold tracking-tight text-black mb-8 text-center">
          Gomonji @ All right reserved
        </p>
      </div>
    </div>
  );
};

// const FlexBoxComponent = () => {
//   return (
//     <div
//       className="container mx-auto branded-section"
//       style={{ paddingTop: "260px" }}
//     >
//       <div className="flex justify-center">
//         {/* First box with secondary color, reduced width, border-radius, and box-shadow */}
//         <div className="w-1/4 border border-gray-500 p-8 shadow-lg rounded-lg m-2">
//           <FontAwesomeIcon
//             icon={faHouse}
//             className="text-4xl"
//           />
//           <p className="font-bold text-xl ">
//             Custom Page
//           </p>
//         </div>
//         {/* Second box with green background, reduced width, border-radius, and box-shadow */}
//         <div className="w-1/4 bg-primary border p-8 shadow-lg  rounded-lg m-2">
//           <FontAwesomeIcon
//             icon={faBookBookmark}
//             className="text-4xl text-white"
//           />
//           <p className="text-white font-bold text-xl ">
//             Seamless Booking
//           </p>
//         </div>
//         {/* Third box with green background, reduced width, border-radius, and box-shadow */}
//         <div className="w-1/4 bg-primary border p-8 shadow-lg rounded-lg m-2">
//           <FontAwesomeIcon
//             icon={faCreditCard}
//             className="text-4xl  text-white"
//           />
//           <p className="text-white ffont-bold text-xl ">
//             Simplified Payment
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };
