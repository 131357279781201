import React, {
  useState,
  useEffect,
} from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import urls from "../../services/axios/urls";
import { toast } from "react-toastify";

const Trips = () => {
  const [trips, setTrips] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    // Fetch data from JSON API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urls.trips}`
        );
        const { pageEdges, totalCount } =
          response.data;
        setTrips(
          pageEdges.map((edge) => edge.node)
        );
        setTotalCount(totalCount);
      } catch (error) {
        toast.error(error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = (id) => {
    // Handle update logic here
    console.log("Update booking with ID:", id);
  };

  const handleDelete = (id) => {
    // Handle delete logic here
    console.log("Delete booking with ID:", id);
  };

  return (
    <div className="overflow-x-auto bg">
      <div className="mb-4 flex">
        <h4 className="text-primary">
          Total: {totalCount}
        </h4>
        <button className="ml-auto bg-primary text-white px-4 py-2 rounded-md">
          {" "}
          <a href="/admin/create-trip">
            Create New Trip
          </a>
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="text-left py-2 px-4">
              Name
            </th>
            <th className="text-left py-2 px-4">
              Trip
            </th>
            <th className="text-left py-2 px-4">
              Trip Date
            </th>
            <th className="text-left py-2 px-4">
              Email
            </th>
            <th className="text-left py-2 px-4">
              Phone Number
            </th>
            <th className="text-left py-2 px-4">
              Price
            </th>
            <th className="text-left py-2 px-4">
              Payment Status
            </th>
            <th className="text-left py-2 px-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {trips.map((trip) => (
            <tr key={trip.id}>
              <td className="border-t py-2 px-4">
                {trip.user.profile
                  ? `${trip.user.profile.firstName} ${trip.user.profile.lastName}`
                  : "N/A"}
              </td>
              <td className="border-t py-2 px-4">
                {trip.title}
              </td>
              <td className="border-t py-2 px-4">
                {new Date(
                  trip.tripStarts
                ).toLocaleDateString()}
              </td>
              <td className="border-t py-2 px-4">
                {trip.user.email}
              </td>
              <td className="border-t py-2 px-4">
                {trip.user.profile
                  ? trip.user.profile.phoneNo ||
                    "NA"
                  : "NA"}
              </td>
              <td className="border-t py-2 px-4">
                {trip.price} {trip.currency}
              </td>
              <td className="border-t py-2 px-4">
                {trip.status ? "Paid" : "Pending"}
              </td>
              <td className="border-t py-2 px-4">
                <div className="flex">
                  <button
                    className="mr-2 text-blue-500 hover:text-blue-700"
                    onClick={() =>
                      handleUpdate(trip.id)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                    />
                    {/* Update */}
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() =>
                      handleDelete(trip.id)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                    />
                    {/* Delete */}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Trips;
