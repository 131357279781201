import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { AuthProvider } from "./state/AuthContext";
import LoginPage from "./layouts/Login";
import SignupPage from "./layouts/Register";
import Admin from "./layouts/Admin/index";
import CreateTrip from "./layouts/Trip/CreateTrip";
import Bookings from "./layouts/Agency/Bookings";
import Trips from "./layouts/Trip";
import Spinner from "./components/Spinner";
import LandingPage from "./layouts/LandingPage";
import StartOnboarding from "./layouts/Users/StartOnboarding";
import AgnencyPage from "./layouts/Agency";
import CompleteOnboarding from "./layouts/Users/CompleteOnboarding";
import Dashboard from "./layouts/Admin/components/Dasboard";
import UserProfile from "./layouts/Users";
import { SingleTrip } from "./layouts/Agency/components/SingleTrip";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "register",
    element: <SignupPage />,
  },
  {
    path: "trip/:id",
    element: <SingleTrip />,
  },
  {
    path: `agency/:id`,
    element: <AgnencyPage />,
  },
  {
    path: "admin",
    element: (
      // <PrivateRoute>
      <Admin />
      // </PrivateRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "onboarding",
        element: <StartOnboarding />,
      },
      {
        path: "complete-onboarding",
        element: <CompleteOnboarding />,
      },
      {
        path: "bookings",
        element: <Bookings />,
      },
      {
        path: "create-trip",
        element: <CreateTrip />,
      },
      {
        path: "trips",
        element: <Trips />,
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider
        router={router}
        fallbackElement={<Spinner />}
      >
        <Routes />
      </RouterProvider>
    </AuthProvider>
  );
}

export default App;
