import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const SideBar = ({ menuItems }) => {
  return (
    <div>
      <ul>
        {menuItems.map((menuItem, index) => (
          <li
            key={index}
            className="flex items-center mb-2"
          >
            <Link
              to={menuItem.to}
              className="flex items-center"
            >
              <span className="mr-2">
                {menuItem.icon}
              </span>
              <span>{menuItem.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

SideBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SideBar;
