import { useState } from "react";
import FormInput from "../../components/FormField";
import axios from "axios";
import urls from "../../services/axios/urls";
import { toast } from "react-toastify";

export default function CreateTrip() {
  if (!localStorage.getItem("authToken")) {
    toast.error("Please login to continue");
    window.location.href = "/login";
  }

  const userId = JSON.parse(
    localStorage.getItem("authToken")
  ).id;

  // const token = JSON.parse(
  //   localStorage.getItem("authToken")
  // ).token;
  const [destination, setDestination] =
    useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] =
    useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState("");
  const [
    numberOfParticipants,
    setNumberOfParticipants,
  ] = useState("");
  const [images, setImages] = useState([]);
  const [itinerary, setItinerary] = useState([]);
  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleNumberOfParticipantsChange = (
    e
  ) => {
    setNumberOfParticipants(e.target.value);
  };

  const handleImagesChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleItineraryChange = (e) => {
    setItinerary([...e.target.files]);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!destination || !title || !price) {
      toast.error("All fields are required");
      return;
    }
    const formData = {
      destination,
      title,
      description,
      startDate: startDate || null,
      endDate: endDate || null,
      price: price || null,
      numberOfParticipants:
        numberOfParticipants || null,
      images: images || null,
      itinerary: itinerary || null,
      userId,
    };
    try {
      await axios
        .post(`${urls.trips}`, formData, {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        })
        .then((response) => {
          if (
            response.status == 200 ||
            response.status == 201
          ) {
            toast.success(
              "Trip created successfully"
            );
            window.location.href = "/admin/trips";
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      toast.error(error.message);
    }

    setDestination("");
    setTitle("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setPrice("");
    setNumberOfParticipants("");
    setImages([]);
    setItinerary([]);
  };

  return (
    <>
      <div className="ml-10">
        <h3 className="text-2xl sm:text-4xl lg:text-5xl font-bold text-primary mb-10 ">
          Create Trip
        </h3>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 md:p-10">
        <div className="">
          <FormInput
            label="Destination"
            id="destination"
            name="destination"
            type="text"
            placeholder="Destination *"
            value={destination}
            onChange={handleDestinationChange}
            required
          />
          <FormInput
            label="Title"
            id="title"
            name="title"
            type="text"
            placeholder="Title *"
            value={title}
            onChange={handleTitleChange}
            required
          />
          <FormInput
            label="Description"
            id="description"
            name="description"
            type="text"
            placeholder="Description (optional)"
            value={description}
            onChange={handleDescriptionChange}
          />
          <FormInput
            label="Start Date"
            id="startDate"
            name="startDate"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            required
          />
          <FormInput
            label="End Date"
            id="endDate"
            name="endDate"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            required
          />
        </div>

        <div>
          <FormInput
            label="Price"
            id="price"
            name="price"
            type="number"
            placeholder="Price *"
            value={price}
            onChange={handlePriceChange}
            required
          />
          <FormInput
            label="Number of Participants"
            id="numberOfParticipants"
            name="numberOfParticipants"
            type="number"
            placeholder="Number of Participants *"
            value={numberOfParticipants}
            onChange={
              handleNumberOfParticipantsChange
            }
            required
          />
          <div className="mb-4">
            <label
              htmlFor="images"
              className="text-sm"
            >
              Images
            </label>
            <input
              type="file"
              id="images"
              name="images"
              onChange={handleImagesChange}
              className="mt-2"
              multiple
              required
            />
          </div>
          <FormInput
            label="Itinerary"
            id="itinerary"
            name="itinerary"
            type="file"
            placeholder="Itinerary (optional)"
            onChange={handleItineraryChange}
            multiple
          />
        </div>

        <div className="items-center mb-4">
          <div>
            <button
              type="submit"
              className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark mt-6"
              onClick={handleOnSubmit}
            >
              Create Trip
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
