import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const AdminNavbar = ({ menuItems }) => {
  const [isDropdownOpen, setIsDropdownOpen] =
    useState(false);

  const handleLogoutButtonClick = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login";
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex justify-between items-center bg-white shadow-md">
      <div className="flex justify-center items-center">
        <img src={logo} alt="logo" />
      </div>
      <div className="relative mr-6">
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-2 focus:outline-none"
        >
          <div className="flex flex-justify">
            <img
              src={avatar}
              alt="U"
              className="w-10 h-10 rounded-full"
            />
            <span>
              <img
                src={ArrowDropDownIcon}
                alt=""
              />
            </span>
          </div>
        </button>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
            <ul className="py-1">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.link}
                    className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-primary"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
              <li>
                <button
                  onClick={
                    handleLogoutButtonClick
                  }
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-red-500 hover:text-primary"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

AdminNavbar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default AdminNavbar;
