export const baseUrl =
  process.env.REACT_APP_BASE_URL;
const urls = {
  login: `${baseUrl}/auth/login`,
  register: `${baseUrl}/auth/register`,
  users: `${baseUrl}/user`,
  user: (userId) =>
    `${baseUrl}/user/${userId}/info`,
  createProfile: (userId) =>
    `${baseUrl}/user/profile/${userId}/create`,
  updateProfile: (userId) =>
    `${baseUrl}/user/profile/${userId}/update`,
  deleteUser: (userId) =>
    `${baseUrl}/user/${userId}/archive`,
  trips: `${baseUrl}/trip`,
  trip: (tripId) =>
    `${baseUrl}/trip/${tripId}/info`,
  updateTrip: (tripId) =>
    `${baseUrl}/trip/${tripId}/update`,
  cacelTrip: (tripId) =>
    `${baseUrl}/trip/${tripId}/cancel`,
  bookings: `${baseUrl}/booking`,
  booking: (bookingId) =>
    `${baseUrl}/booking/${bookingId}/info`,
  updateBooking: (bookingId) =>
    `${baseUrl}/booking/${bookingId}/update`,
  cacelBooking: (bookingId) =>
    `${baseUrl}/booking/${bookingId}/cancel`,
};

export default urls;
