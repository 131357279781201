import { TripList } from "./components/TripDetails";
import Footer from "../../components/Footers";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import urls from "../../services/axios/urls";
import axios from "axios";
import { toast } from "react-toastify";

export default function AgentPage() {
  const agencyId = useParams().id;

  console.log(agencyId);

  const [agencyTrips, setAgencyTrips] = useState(
    []
  );

  const [userProfile, setUserProfile] = useState(
    {}
  );

  console.log(agencyTrips, userProfile);

  const URI = urls.user(agencyId);

  const fetchAgencyData = async () => {
    try {
      const response = await axios.get(URI);
      setAgencyTrips(response.data?.trips);
      setUserProfile(response.data?.profile);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchAgencyData();
  }, [agencyId]);

  return (
    <>
      <div className="bg-hero relative">
        <NavBar />
        <AgencyHeader userProfile={userProfile} />
      </div>
      <AllTripsAvailableSection
        agencyTrips={agencyTrips}
      />
      <Footer />
    </>
  );
}

const NavBar = () => {
  return (
    <nav className="absolute top-4 left-1/2 transform -translate-x-1/2 text-white pt-7">
      <ul className="flex space-x-4">
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#properties">Packages</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

const AgencyHeader = ({ userProfile }) => {
  return (
    <div className="relative">
      <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:py-32 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl sm:text-5xl lg:text-5xl font-light tracking-tight text-secondary pt-5">
            {userProfile?.companyName &&
              `${userProfile?.companyName} `}
          </h1>
          <p className="mt-6 text-base sm:text-lg lg:text-xl leading-6 text-white mb-8">
            Ready to Explore? These Trips Await!
          </p>
          <input
            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
            placeholder="Search"
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

AgencyHeader.propTypes = {
  userProfile: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const AllTripsAvailableSection = ({
  agencyTrips,
}) => {
  AllTripsAvailableSection.propTypes = {
    agencyTrips: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired || null,
        picture: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        noOfPeople: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        bookButton: PropTypes.node.isRequired,
      })
    ).isRequired,
  };
  return (
    <>
      <div className="text-center mt-10 mb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6"></div>
      </div>

      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-6">
          All Trips Available
        </h1>
        <TripList trips={agencyTrips} />
      </div>
    </>
  );
};

const PolularTrips = ({ trips }) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {trips.map((trip, index) => (
        <TripDetails key={index} {...trip} />
      ))}
    </div>
  );
};

PolularTrips.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const TripDetails = ({ picture, country }) => {
  return (
    <div className="bg-white rounded-lg shadow-md">
      <img
        src={picture}
        alt={country}
        className="w-full h-auto mb-4"
      />
      <h2 className="text-lg font-semibold mb-2">
        {country}
      </h2>
    </div>
  );
};

TripDetails.propTypes = {
  picture: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};
