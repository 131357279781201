import React, {
  useState,
  useEffect,
} from "react";
import axios from "axios";
import urls from "../../services/axios/urls";
import { toast } from "react-toastify";

const UserProfile = () => {
  const userId = JSON.parse(
    localStorage.getItem("authToken")
  ).id;
  if (!userId) {
    alert("Please login to continue");
    toast.error("Please login to continue");
    window.location.href = "/login";
  }
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${urls.user}/${userId}/info`
        );
        setUser(response.data);
      } catch (error) {
        console.error(
          "Error fetching user data:",
          error
        );
        toast.error("Error fetching user data");
      }
    };

    fetchUser();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  const { email, profile, trips } = user;

  return (
    <div className="bg-white p-8 rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-4">
        User Profile
      </h2>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            First Name:
          </label>
          <p>{profile.firstName}</p>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Last Name:
          </label>
          <p>{profile.lastName}</p>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email:
          </label>
          <p>{email}</p>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Company Name:
          </label>
          <p>{profile.companyName}</p>
        </div>
        {/* Add more user details as needed */}
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">
          User Trips
        </h2>
        {trips.map((trip) => (
          <div key={trip.id} className="mb-4">
            <p className="text-gray-700">
              Destination: {trip.destination}
            </p>
            <p className="text-gray-700">
              Title: {trip.title}
            </p>
            <p className="text-gray-700">
              Price: {trip.price}
            </p>
            {/* Add more trip details as needed */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserProfile;
